* {
  font-family: 'Inter', sans-serif;
}

nav {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  height: 98vh;
  width: 10%;
  overflow-y: auto;;
}

main {
  width: 100%;
  border: 1px solid #e7e7e7;
  margin: 10px;
  border-radius: 12px;
  background-color: white;
  height: 93vh;
  background-color: #fcfcfc;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  padding: 20px;
}

button {
  padding: 8px;
  background-color: white;
  border: 1px solid rgb(207, 207, 207);
  cursor: pointer;
  border-radius: 12px;
box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

button:hover {
  background-color: #f3f3f3;
  transition: 0.3s;
  scale: 1.03;
  transition-property: all;
}

button:not(:hover) {
  scale: 1;
  transition-property: all;
  transition-duration: 0.15s;
}

button:active {
  scale: 0.95;
  transition-property: all;
}

body {
  overflow-y: hidden;
}

.App {
  display: flex;
  background-color: #f9f9f9;
  padding-top: 10px;
}

h1 {
  margin: 0;
  font-weight: 400;
}

.tree-container {
  padding: 20px;
  margin: 20px 0;
  height: 88%;
  background: #f5f5f5;
  border-radius: 6px
}

.input {
  padding: 0.875rem;
  font-size: 1rem;
  border: 1.5px solid #000;
  border-radius: 0.5rem;
  box-shadow: 2.5px 3px 0 #000;
  outline: none;
  transition: ease 0.25s;
}

.input:focus {
  box-shadow: 5.5px 7px 0 black;
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
  width: 20%;
  margin: 100px auto;
}

.m-0 {
  margin: 0;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}
